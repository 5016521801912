import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { ABOUT, CONTACT, HOME, IMAGE_GALLERY, SIGN_UP } from "../../helpers/routes";

export default function SiteNavMobile({ navigateToRoute, clickNavDrawer }) {
  return (
    <SiteNavMobileContainer>
      <div className="backdrop-container" id="backdrop"></div>
      <nav id="sidenav" className="sidenav-container">
        <ul role="menu">
          <li id={HOME} className="line" role="menuitem" onClick={() => navigateToRoute(HOME)}>
            Naslovna
          </li>
          <li id={ABOUT} className="line" role="menuitem" onClick={() => navigateToRoute(ABOUT)}>
            O nama
          </li>
          <li
            id={IMAGE_GALLERY}
            className="line"
            role="menuitem"
            onClick={() => navigateToRoute(IMAGE_GALLERY)}
          >
            Galerija slika
          </li>
          <li
            id={CONTACT}
            className="line"
            role="menuitem"
            onClick={() => navigateToRoute(CONTACT)}
          >
            Kontakt
          </li>
          <li
            id={SIGN_UP}
            className="line"
            role="menuitem"
            onClick={() => navigateToRoute(SIGN_UP)}
          >
            Učlani se
          </li>
        </ul>
      </nav>
      <div id="hamburger-nav" css={NavTrigger} onClick={clickNavDrawer}>
        <i></i>
        <i></i>
        <i></i>
      </div>
    </SiteNavMobileContainer>
  );
}

const SiteNavMobileContainer = styled.div`
  ul {
    margin-top: 2rem;
    list-style: none;

    li {
      margin-top: 1rem;
      cursor: pointer;
      color: black;
    }
  }
`;

const NavTrigger = css`
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin-top: 10px;

  i {
    background-color: black;
    border-radius: 2px;
    content: "";
    display: block;
    width: 100%;
    height: 4px;
  }

  i:nth-of-type(2) {
    margin: 5px 0;
  }

  &.animate {
    i:nth-of-type(1) {
      -webkit-animation: outT 0.8s backwards;
      animation: outT 0.8s backwards;
      -webkit-animation-direction: reverse;
      animation-direction: reverse;
    }

    i:nth-of-type(2) {
      margin: 5px 0;
      -webkit-animation: outM 0.8s backwards;
      animation: outM 0.8s backwards;
      -webkit-animation-direction: reverse;
      animation-direction: reverse;
    }

    i:nth-of-type(3) {
      -webkit-animation: outBtm 0.8s backwards;
      animation: outBtm 0.8s backwards;
      -webkit-animation-direction: reverse;
      animation-direction: reverse;
    }
  }

  &.active {
    i:nth-of-type(1) {
      -webkit-animation: inT 0.8s forwards;
      animation: inT 0.8s forwards;
    }
    i:nth-of-type(2) {
      -webkit-animation: inM 0.8s forwards;
      animation: inM 0.8s forwards;
    }
    i:nth-of-type(3) {
      -webkit-animation: inBtm 0.8s forwards;
      animation: inBtm 0.8s forwards;
    }
  }

  @-webkit-keyframes inM {
    50% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(45deg);
    }
  }
  @keyframes inM {
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }

  @-webkit-keyframes outM {
    50% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(45deg);
    }
  }
  @keyframes outM {
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }

  @-webkit-keyframes inT {
    0% {
      -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
      -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
      -webkit-transform: translateY(9px) rotate(135deg);
    }
  }
  @keyframes inT {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(9px) rotate(0deg);
    }
    100% {
      transform: translateY(9px) rotate(135deg);
    }
  }

  @-webkit-keyframes outT {
    0% {
      -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
      -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
      -webkit-transform: translateY(9px) rotate(135deg);
    }
  }
  @keyframes outT {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(9px) rotate(0deg);
    }
    100% {
      transform: translateY(9px) rotate(135deg);
    }
  }

  @-webkit-keyframes inBtm {
    0% {
      -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
    }
  }
  @keyframes inBtm {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(-9px) rotate(0deg);
    }
    100% {
      transform: translateY(-9px) rotate(135deg);
    }
  }

  @-webkit-keyframes outBtm {
    0% {
      -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
    }
  }
  @keyframes outBtm {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(-9px) rotate(0deg);
    }
    100% {
      transform: translateY(-9px) rotate(135deg);
    }
  }
`;
