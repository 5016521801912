import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { useLocation, navigate } from "@reach/router";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { colors } from "../../styles/colors";
import { Logo } from "../shared/Logo";
import { ABOUT, CONTACT, HOME, IMAGE_GALLERY, SIGN_UP } from "../../helpers/routes";
import useWindowDimensions from "../../helpers/hooks/useWindowDimensions";
import SiteNavMobile from "./SiteNavMobile";

export default function SiteNav({ isPost = false, postTitle = "" }) {
  const { width } = useWindowDimensions();
  const subscribe = useRef();
  const titleRef = useRef();
  const location = useLocation();

  let lastScrollY = 0;
  let ticking = false;
  const [showTitle, setShowTitle] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const executeScroll = useCallback(onScroll, [subscribe]);

  useEffect(() => {
    lastScrollY = window.scrollY;

    if (document.getElementById(location.pathname)) {
      document.getElementById(location.pathname).className = "line active";
    }

    if (isPost) {
      window.addEventListener("scroll", executeScroll, { passive: true });
    }

    return () => {
      window.removeEventListener("scroll", executeScroll);
    };
  }, []);

  useEffect(() => {
    if (width < 754) {
      return setIsMobile(true);
    }

    setIsMobile(false);
  }, [width]);

  function onScroll() {
    if (!titleRef || !titleRef.current) {
      return;
    }

    if (!ticking) {
      requestAnimationFrame(update);
    }

    ticking = true;
  }

  function update() {
    if (!titleRef || !titleRef.current) {
      return;
    }

    lastScrollY = window.scrollY;

    const trigger = titleRef.current.getBoundingClientRect().top;
    const triggerOffset = titleRef.current.offsetHeight + 35;

    if (lastScrollY >= trigger + triggerOffset) {
      setShowTitle(true);
    } else {
      setShowTitle(false);
    }

    ticking = false;
  }

  function clickNavDrawer() {
    const navClass = document.getElementById("hamburger-nav").className;

    if (navClass.includes("active")) {
      document.getElementById("hamburger-nav").className = navClass.replace(/active/, "");
      closeNav();
    } else {
      document.getElementById("hamburger-nav").className = `${navClass} animate active`;
      openNav();
    }
  }

  function openNav() {
    document.getElementById("sidenav").style.width = "70%";
    document.getElementById("backdrop").style.display = "block";
  }

  function closeNav() {
    document.getElementById("sidenav").style.width = "0";
    document.getElementById("backdrop").style.display = "none";
  }

  function navigateToRoute(route) {
    closeNav();
    clickNavDrawer();

    setTimeout(() => {
      navigate(route);
    }, 500);
  }

  if (isMobile) {
    return (
      <nav css={MobileNavStyles}>
        <Link to={HOME}>
          <Logo />
        </Link>
        <SiteNavMobile navigateToRoute={navigateToRoute} clickNavDrawer={clickNavDrawer} />
      </nav>
    );
  }

  return (
    <nav css={SiteNavStyles}>
      <Link to={HOME}>
        <Logo />
      </Link>
      <SiteNavLeft className="site-nav-left">
        <SiteNavContent css={[showTitle ? HideNav : ""]}>
          <ul css={NavStyles} role="menu">
            <li id={HOME} className="line" role="menuitem">
              <Link to={HOME}>Naslovna</Link>
            </li>
            <li id={ABOUT} className="line" role="menuitem">
              <Link to={ABOUT}>O nama</Link>
            </li>
            <li id={IMAGE_GALLERY} className="line" role="menuitem">
              <Link to={IMAGE_GALLERY}>Galerija slika</Link>
            </li>
            <li id={CONTACT} className="line" role="menuitem">
              <Link to={CONTACT}>Kontakt</Link>
            </li>
            <li id={SIGN_UP} className="line" role="menuitem">
              <Link to={SIGN_UP}>Učlani se</Link>
            </li>
          </ul>
          {isPost && !isMobile && (
            <NavPostTitle ref={titleRef} className="nav-post-title">
              {postTitle}
            </NavPostTitle>
          )}
        </SiteNavContent>
      </SiteNavLeft>
      <SiteNavRight></SiteNavRight>
    </nav>
  );
}

const MobileNavStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ul {
    min-width: 150px;
  }

  .backdrop-container {
    display: none;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 58px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-animation: fadeIn 1.2s ease-in-out;
    animation: fadeIn 1.2s ease-in-out;
  }

  .sidenav-container {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 3;
    top: 58px;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const SiteNavMain = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background: ${colors.white};
  box-shadow: 0px 6px 9px -4px rgba(0, 0, 0, 0.15);
`;

const SiteNavStyles = css`
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: hidden;
  height: 64px;
  font-size: 1.3rem;

  // .gatsby-image-wrapper {
  //   top: 3px;
  //   width: 220px !important;
  // }
`;

const SiteNavLeft = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  padding: 10px 0 80px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  white-space: nowrap;

  ul {
    left: 10px;
  }

  -ms-overflow-scrolling: touch;

  @media (max-width: 811px) {
    // margin-right: 0;
    padding-left: 5vw;
    display: flex;
    justify-content: flex-end;
  }
`;

const SiteNavContent = styled.div`
  position: relative;
  align-self: flex-start;
`;

const NavStyles = css`
  position: absolute;
  z-index: 1000;
  display: flex;
  margin: 0 0 0 -12px;
  padding: 0;
  list-style: none;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);

  li {
    display: block;
    margin: 0;
    padding: 0;
  }

  .line {
    display: inline-block;
    color: white;
    text-decoration: none;
    margin: 0 5px;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 3px;
      background: ${colors.blue};
      transition: width 0.3s;
    }

    &.active:after {
      width: 100%;
    }

    &:hover::after{
      width: 100%;
    }
  }

  li a {
    position: relative;
    display: block;
    padding: 12px 12px;
    text-decoration: none;
    color: ${colors.black};
    box-shadow: inset 0 0 0 0 white;
  }

  // li a:hover {
  //   text-decoration: none;
  //   color: white;
  //   transition: 0.8s;
  //   box-shadow: inset 200px 0 0 0 ${colors.blue};
  // }
`;

const SiteNavRight = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  height: 64px;

  @media (max-width: 700px) {
    display: none;
  }
`;

const NavPostTitle = styled.span`
  visibility: hidden;
  position: absolute;
  top: 9px;
  color: ${colors.black};
  font-size: 1.7rem;
  font-weight: 400;
  text-transform: none;
  opacity: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(175%);

  .dash {
    left: -25px;
  }

  .dash:before {
    content: "– ";
    opacity: 0.5;
  }
`;

const HideNav = css`
  ul {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-175%);
  }
  .nav-post-title {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`;
