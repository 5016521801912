import { Link } from "gatsby";
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { colors } from "../styles/colors";
import { outer, inner } from "../styles/shared";
import { ABOUT, CONTACT, HOME, IMAGE_GALLERY, SIGN_UP } from "../helpers/routes";

export const Footer = () => {
  return (
    <footer css={[outer, SiteFooter]}>
      <div css={[inner]}>
        <SiteFooterNav>
          <div css={SiteFooterContent}>
            <Link to={HOME}>Naslovna</Link>
            <Link to={ABOUT}>O nama</Link>
            <Link to={IMAGE_GALLERY}>Galerija slika</Link>
            <Link to={CONTACT}>Kontakt</Link>
            <Link to={SIGN_UP}>Učlani se</Link>
          </div>
          <span>© TOP AMMO {new Date().getFullYear()}</span>
        </SiteFooterNav>
      </div>
    </footer>
  );
};

const SiteFooter = css`
  padding-top: 20px;
  padding-bottom: 60px;
  color: #fff;
  background: ${colors.blue};
`;

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.3rem;

  a {
    color: rgba(255, 255, 255, 0.7);
  }

  a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }

  @media (max-width: 535px) {
    flex-direction: column;
    display: inline-grid;

    a {
      margin: 0;
    }
  }
`;

const SiteFooterNav = styled.nav`
  display: flex;
  justify-content: space-between;

  a {
    position: relative;
    margin-left: 20px;
  }

  a:before {
    content: "";
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    border-radius: 100%;
  }

  a:first-of-type:before {
    display: none;
  }

  span {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
  }

  @media (max-width: 650px) {
    a:first-of-type {
      margin-left: 0;
    }
  }

  @media (max-width: 345px) {
    flex-direction: column;
  }
`;
