export const colors = {
  blue: "#3eb0ef",
  green: "#a4d037",
  purple: "#7848f4",
  yellow: "#fecd35",
  red: "#f05230",
  blue: "#40699f",
  darkgrey: "#15171A",
  midgrey: "#6a7c94",
  lightgrey: "#c5d2d9",
  whitegrey: "#e5eff5",
  pink: "#fa3a57",
  brown: "#a3821a",
  // darkmode: color(var(--darkgrey) l(+2%)),
  darkmode: "#282a2c",
  white: "#fff",
  black: "#131315",

  boxShadow: "0px 6px 9px -4px rgba(0, 0, 0, 0.45)",
}
