import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { pathOr } from "ramda"
import Img from "gatsby-image"
import styled from "@emotion/styled"

export const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: allWordpressWpMedia(filter: { id: { eq: "91009139-0d97-5c08-b328-a60a815d00b5" } }) {
        edges {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const logo = pathOr(null, ["logo", "edges", "0", "node", "localFile", "childImageSharp"], data)

  return logo ? (
    <ImageContainer>
      <Img fluid={logo.fluid} />{" "}
    </ImageContainer>
  ) : (
    <div />
  )
}

const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    width: 200px;
    background-color: transparent;
  }
`
